import axios from 'axios';
import { useEffect, useState } from 'react';

import { ReactComponent as CopticCross } from '../assets/coptic_cross.svg';
import settings from '../config';

export const Board = () => {
  const [questions, setQuestions] = useState([]);

  const getQuestions = async () => {
    const response = await axios.get(`${settings.api}questions`, {});

    if (response.data.statusCode === 200) {
      setQuestions(response.data.data);
    }
  };

  useEffect(() => {
    getQuestions();
    const intervalHandler = setInterval(getQuestions, 5000);

    return () => clearInterval(intervalHandler);
  }, []);

  const updateQuestion = async (question) => {
    const response = await axios.post(`${settings.api}questions/update`, {
      ...question,
      status: 'answered'
    });

    if (response.data.statusCode === 200) {
      getQuestions();
    }
  };

  return (
    <div className='flex flex-col p-2 gap-2'>
      <div className='flex justify-between items-center text-center font-bold'>
        <CopticCross className='w-20 h-20 text-4xl' /> Ask His Grace{' '}
        <CopticCross className='w-20 h-20' />
      </div>

      {questions
        .filter((question) => question.status === 'approved')
        .map((question) => {
          let color;
          let buttonColor;
          switch (question.status) {
            case 'approved':
              color = 'bg-blue-100';
              buttonColor = 'bg-blue-200';
              break;
            case 'answered':
              color = 'bg-green-100';
              buttonColor = 'bg-green-200';
              break;
            case 'pending':
              color = 'bg-red-100';
              buttonColor = 'bg-red-200';
              break;
            default:
              color = 'bg-blue-100';
              buttonColor = 'bg-blue-200';
              break;
          }

          return (
            <div
              key={question.id}
              className={`w-full p-2 py-4 rounded-lg ${color} text-4xl`}
            >
              {question.question}
              <div className='flex items-center justify-between mt-2 text-2xl'>
                <div className=''>{question.sender}</div>
                <div
                  className={`cursor-pointer p-2 ${buttonColor} rounded-xl`}
                  onClick={() => updateQuestion(question)}
                >
                  Answered ?
                </div>
              </div>
            </div>
          );
        })}

      {questions
        .filter((question) => question.status === 'answered')
        .map((question) => {
          let color;
          switch (question.status) {
            case 'approved':
              color = 'bg-blue-100';
              break;
            case 'answered':
              color = 'bg-green-100';
              break;
            case 'pending':
              color = 'bg-red-100';
              break;
            default:
              color = 'bg-blue-100';
              break;
          }

          return (
            <div
              key={question.id}
              className={`w-full p-2 py-4 rounded-lg ${color} text-4xl`}
            >
              {question.question}
              <div className='flex items-center justify-between mt-2 text-2xl'>
                <div className=''>{question.sender}</div>
              </div>
            </div>
          );
        })}
    </div>
  );
};
