import { ReactComponent as QRCode } from './assets/qrcode.svg';
import './output.css';

function App() {
  return (
    <div className='flex justify-center w-full h-full'>
      <QRCode className='h-full' />
    </div>
  );
}

export default App;
