import axios from 'axios';
import { useState } from 'react';

import { ReactComponent as CopticCross } from '../assets/coptic_cross.svg';
import settings from '../config';

export const Ask = () => {
  const [form, setForm] = useState({
    question: '',
    sender: 'anonymous',
    status: 'pending'
  });
  const [submitted, setSubmitted] = useState(false);

  return (
    <div className='flex flex-col w-full p-2 gap-4'>
      <div className='flex justify-between items-center text-center font-bold'>
        <CopticCross className='w-20 h-20 text-4xl' /> Ask His Grace{' '}
        <CopticCross className='w-20 h-20' />
      </div>
      <label>
        Your name:
        <input
          className='border w-full h-8 p-2 rounded-lg'
          value={form.sender}
          name='sender'
          placeholder='Anonymous'
          onChange={(e) => {
            setForm({
              ...form,
              sender: e.target.value
            });
          }}
        />
      </label>
      <label>
        Your Question:
        <textarea
          className='border w-full h-24 p-2 rounded-lg'
          name='question'
          onChange={(e) => {
            setForm({
              ...form,
              question: e.target.value
            });
          }}
          value={form.question}
        />
      </label>
      <div className='flex gap-2'>
        <button
          className='border w-24 rounded-lg'
          onClick={async () => {
            const response = await axios.post(
              `${settings.api}questions/create`,
              form
            );

            if (response.data.statusCode === 200) {
              setSubmitted(response.data.data);
            }
          }}
        >
          Submit
        </button>{' '}
        {submitted &&
          ((
            <div className='text-green-800'>
              √ Your question has been submitted successfully
            </div>
          ) ||
            null)}
      </div>
    </div>
  );
};
